import { FormattedMessage, IntlShape, defineMessages } from '@alltrails/shared/react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { ReactNode } from 'react';
import { StaticImageData } from 'next/image';
import phoneSrcAvif from '../../assets/phone.avif';
// download image imports
import downloadDaDk from './images/download/download-da-DK.png';
import downloadDeDe from './images/download/download-de-DE.png';
import downloadEnGb from './images/download/download-en-GB.png';
import downloadEnUS from './images/download/download-en-US.png';
import downloadEsEs from './images/download/download-es-ES.png';
import downloadEsMx from './images/download/download-es-MX.png';
import downloadFrFr from './images/download/download-fr-FR.png';
import downloadItIt from './images/download/download-it-IT.png';
import downloadNbNo from './images/download/download-nb-NO.png';
import downloadNlNl from './images/download/download-nl-NL.png';
import downloadPlPl from './images/download/download-pl-PL.png';
import downloadPtBr from './images/download/download-pt-BR.png';
import downloadPtPt from './images/download/download-pt-PT.png';
import downloadSvSE from './images/download/download-sv-SE.png';
// navigate image imports
import navigateDaDk from './images/navigate/navigate-da-DK.png';
import navigateDeDe from './images/navigate/navigate-de-DE.png';
import navigateEnGb from './images/navigate/navigate-en-GB.png';
import navigateEnUS from './images/navigate/navigate-en-US.png';
import navigateEsEs from './images/navigate/navigate-es-ES.png';
import navigateEsMx from './images/navigate/navigate-es-MX.png';
import navigateFrFr from './images/navigate/navigate-fr-FR.png';
import navigateItIt from './images/navigate/navigate-it-IT.png';
import navigateNbNo from './images/navigate/navigate-nb-NO.png';
import navigateNlNl from './images/navigate/navigate-nl-NL.png';
import navigatePlPl from './images/navigate/navigate-pl-PL.png';
import navigatePtBr from './images/navigate/navigate-pt-BR.png';
import navigatePtPt from './images/navigate/navigate-pt-PT.png';
import navigateSvSE from './images/navigate/navigate-sv-SE.png';
// liveshare image imports
import liveshareDaDk from './images/liveshare/liveshare-da-DK.png';
import liveshareDeDe from './images/liveshare/liveshare-de-DE.png';
import liveshareEnGb from './images/liveshare/liveshare-en-GB.png';
import liveshareEnUS from './images/liveshare/liveshare-en-US.png';
import liveshareEsEs from './images/liveshare/liveshare-es-ES.png';
import liveshareEsMx from './images/liveshare/liveshare-es-MX.png';
import liveshareFrFr from './images/liveshare/liveshare-fr-FR.png';
import liveshareItIt from './images/liveshare/liveshare-it-IT.png';
import liveshareNbNo from './images/liveshare/liveshare-nb-NO.png';
import liveshareNlNl from './images/liveshare/liveshare-nl-NL.png';
import livesharePlPl from './images/liveshare/liveshare-pl-PL.png';
import livesharePtBr from './images/liveshare/liveshare-pt-BR.png';
import livesharePtPt from './images/liveshare/liveshare-pt-PT.png';
import liveshareSvSE from './images/liveshare/liveshare-sv-SE.png';

const ALT_TEXT = defineMessages({
  DEFAULT_ALT_Text: {
    defaultMessage: 'An app screen shows nearby trails and an option to explore wildflower trails.'
  },
  DOWNLOAD_ALT_Text: {
    defaultMessage: 'An app screen shows several trail map options available for download.'
  },
  NAVIGATE_ALT_Text: {
    defaultMessage: 'An app screen shows live stats and GPS navigation on an interactive map.'
  },
  LIVE_SHARE_ALT_Text: {
    defaultMessage: 'A screen shows a trailgoer’s location on a route map, their real-time stats, and a button to cheer them on.'
  }
});

export type InterstitialVariants = 'generic' | 'download' | 'navigate' | 'liveshare';

type InterstitialContent = {
  header: ReactNode;
  altText: string;
  phoneImg: StaticImageData;
};

type VariantImages = {
  [key in InterstitialVariants]: {
    [key in LanguageRegionCode]: StaticImageData;
  };
};

type InterstitalContentVariants = {
  [key in InterstitialVariants]: InterstitialContent;
};

const variantImages: VariantImages = {
  // Use old phone image for now. Likely will be redesigned & use localized imagery.
  generic: {
    [LanguageRegionCode.Danish]: phoneSrcAvif,
    [LanguageRegionCode.Dutch]: phoneSrcAvif,
    [LanguageRegionCode.EnglishGB]: phoneSrcAvif,
    [LanguageRegionCode.English]: phoneSrcAvif,
    [LanguageRegionCode.French]: phoneSrcAvif,
    [LanguageRegionCode.German]: phoneSrcAvif,
    [LanguageRegionCode.Italian]: phoneSrcAvif,
    [LanguageRegionCode.Norwegian]: phoneSrcAvif,
    [LanguageRegionCode.Polish]: phoneSrcAvif,
    [LanguageRegionCode.Portuguese]: phoneSrcAvif,
    [LanguageRegionCode.PortugueseBrazilian]: phoneSrcAvif,
    [LanguageRegionCode.Spanish]: phoneSrcAvif,
    [LanguageRegionCode.SpanishLatAm]: phoneSrcAvif,
    [LanguageRegionCode.Swedish]: phoneSrcAvif
  },
  download: {
    [LanguageRegionCode.Danish]: downloadDaDk,
    [LanguageRegionCode.Dutch]: downloadNlNl,
    [LanguageRegionCode.EnglishGB]: downloadEnGb,
    [LanguageRegionCode.English]: downloadEnUS,
    [LanguageRegionCode.French]: downloadFrFr,
    [LanguageRegionCode.German]: downloadDeDe,
    [LanguageRegionCode.Italian]: downloadItIt,
    [LanguageRegionCode.Norwegian]: downloadNbNo,
    [LanguageRegionCode.Polish]: downloadPlPl,
    [LanguageRegionCode.Portuguese]: downloadPtPt,
    [LanguageRegionCode.PortugueseBrazilian]: downloadPtBr,
    [LanguageRegionCode.Spanish]: downloadEsEs,
    [LanguageRegionCode.SpanishLatAm]: downloadEsMx,
    [LanguageRegionCode.Swedish]: downloadSvSE
  },
  navigate: {
    [LanguageRegionCode.Danish]: navigateDaDk,
    [LanguageRegionCode.Dutch]: navigateNlNl,
    [LanguageRegionCode.EnglishGB]: navigateEnGb,
    [LanguageRegionCode.English]: navigateEnUS,
    [LanguageRegionCode.French]: navigateFrFr,
    [LanguageRegionCode.German]: navigateDeDe,
    [LanguageRegionCode.Italian]: navigateItIt,
    [LanguageRegionCode.Norwegian]: navigateNbNo,
    [LanguageRegionCode.Polish]: navigatePlPl,
    [LanguageRegionCode.Portuguese]: navigatePtPt,
    [LanguageRegionCode.PortugueseBrazilian]: navigatePtBr,
    [LanguageRegionCode.Spanish]: navigateEsEs,
    [LanguageRegionCode.SpanishLatAm]: navigateEsMx,
    [LanguageRegionCode.Swedish]: navigateSvSE
  },
  liveshare: {
    [LanguageRegionCode.Danish]: liveshareDaDk,
    [LanguageRegionCode.Dutch]: liveshareNlNl,
    [LanguageRegionCode.EnglishGB]: liveshareEnGb,
    [LanguageRegionCode.English]: liveshareEnUS,
    [LanguageRegionCode.French]: liveshareFrFr,
    [LanguageRegionCode.German]: liveshareDeDe,
    [LanguageRegionCode.Italian]: liveshareItIt,
    [LanguageRegionCode.Norwegian]: liveshareNbNo,
    [LanguageRegionCode.Polish]: livesharePlPl,
    [LanguageRegionCode.Portuguese]: livesharePtPt,
    [LanguageRegionCode.PortugueseBrazilian]: livesharePtBr,
    [LanguageRegionCode.Spanish]: liveshareEsEs,
    [LanguageRegionCode.SpanishLatAm]: liveshareEsMx,
    [LanguageRegionCode.Swedish]: liveshareSvSE
  }
};

const getInterstitialContent = (
  variant: keyof InterstitalContentVariants,
  languageRegionCode: LanguageRegionCode,
  intl: IntlShape
): InterstitialContent => {
  const phoneImg = variantImages[variant][languageRegionCode];
  const content: InterstitalContentVariants = {
    generic: {
      header: <FormattedMessage defaultMessage="AllTrails is better in the app" />,
      altText: intl.formatMessage(ALT_TEXT.DEFAULT_ALT_Text),
      phoneImg
    },
    download: {
      header: <FormattedMessage defaultMessage="Download to go where cell service can't" />,
      altText: intl.formatMessage(ALT_TEXT.DOWNLOAD_ALT_Text),
      phoneImg
    },
    navigate: {
      header: <FormattedMessage defaultMessage="Stay on route and track your travels" />,
      altText: intl.formatMessage(ALT_TEXT.NAVIGATE_ALT_Text),
      phoneImg
    },
    liveshare: {
      header: <FormattedMessage defaultMessage="Live share your adventures" />,
      altText: intl.formatMessage(ALT_TEXT.LIVE_SHARE_ALT_Text),
      phoneImg
    }
  };

  return content[variant];
};
export default getInterstitialContent;
