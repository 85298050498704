import Icon from '../components/Icon';

const CheckSm = Icon(
  'check-sm',
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.545 10.712a1 1 0 0 0-1.404-1.424l-4.462 4.398-1.827-1.764a1 1 0 0 0-1.39 1.44l2.53 2.44a1 1 0 0 0 1.396-.007l5.157-5.083Z"
    />
  </>
);

export default CheckSm;
