import IconButton from '@alltrails/denali/components/IconButton';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import StarRating from '@alltrails/denali/components/StarRating';
import { useIntl } from '@alltrails/shared/react-intl';
import Texture from '@alltrails/shared/denali/components/Texture';
import { useLanguageRegionCode } from '@alltrails/language';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import InstallPromptType from '@alltrails/analytics/enums/InstallPromptType';
import Button from '@alltrails/denali/components/Button';
import Close from '@alltrails/denali/icons/Close';
import logInstallPromptViewed from '@alltrails/analytics/events/logInstallPromptViewed';
import { useEffect, useState } from 'react';
import InstallPromptSource from '@alltrails/analytics/enums/InstallPromptSource';
import logInstallPromptDismissed from '@alltrails/analytics/events/logInstallPromptDismissed';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { Image, ModalPortal, Typography, isAndroid, useScreenLock } from '@alltrails/core';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useFeatures } from '@alltrails/features';
import { useSearchParams } from 'next/navigation';
import { useExperiments } from '@alltrails/experiments';
import appIconSrc from '../../assets/app-icon.avif';
import useDeeplink from '../../hooks/useDeeplink';
import { deepLinkRedirect } from '../../utils';
import styles from './InstallInterstitial.module.scss';
import getInterstitialContent from './constants';
import getVariantByTrigger from './utils/getVariantByTrigger';

type InstallInterstitialProps = {
  promptLocation: InstallPromptLocation;
  pageId?: number;
  isOpen?: boolean;
  onRequestClose?: () => void;
  displayTrigger?: CarouselDisplayTrigger;
};

// Design link: https://www.figma.com/file/oevB4bXh8KLLDe1Yp8rBW2?type=design%27&node-id=11925:74124

const InstallInterstitial = ({ promptLocation, pageId, isOpen: isOpenAttempt = false, displayTrigger, onRequestClose }: InstallInterstitialProps) => {
  const experiments = useExperiments();
  const isAppsFlyerEnabled = useFeatures().includes('appsflyer_migration');
  const isMobile = useIsMobileSizedScreen();
  const intl = useIntl();
  const [isMounted, setIsMounted] = useState(false);
  const isOpen = isMounted && isOpenAttempt && isMobile;
  const deeplink = useDeeplink({ pageType: 'trail', pageId, isOpen, promptType: 'interstitial', displayTrigger });
  const languageRegionCode = useLanguageRegionCode();
  useScreenLock(isOpen);

  const scrollLimitXp = experiments['web-growth-rm-trail-page-scroll-auth-v2'];
  const isScrollLimitActive = scrollLimitXp?.value?.includes('treatment');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      logInstallPromptViewed({
        install_prompt_location: promptLocation,
        install_prompt_type: InstallPromptType.Interstitial,
        install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native,
        signup_modal_trigger: displayTrigger
      });
    }
  }, [displayTrigger, isAppsFlyerEnabled, isOpen, promptLocation]);

  const handleCloseClick = () => {
    logInstallPromptDismissed({
      install_prompt_location: promptLocation,
      install_prompt_type: InstallPromptType.Interstitial,
      install_prompt_source: isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native
    });
    onRequestClose?.();
  };

  let searchParamsVariant;
  const params = useSearchParams();

  if (params) {
    searchParamsVariant = params.get('variant');
  }

  const variant = getVariantByTrigger(displayTrigger);
  const { header, altText, phoneImg } = getInterstitialContent(searchParamsVariant || variant, languageRegionCode, intl);

  return isOpen ? (
    <ModalPortal>
      <FocusTrap
        active={isOpen}
        // fallbackFocus is used to prevent errors in testing https://github.com/focus-trap/focus-trap-react/issues/91
        focusTrapOptions={{
          fallbackFocus: '#interstitial-container'
        }}
      >
        <div
          className={classNames(
            {
              [styles.containerWithFadeIn]: isScrollLimitActive
            },
            styles.container
          )}
          id="interstitial-container"
        >
          <div className={styles.imageContainer}>
            {variant === 'generic' && <Texture variant="leaves" imageSize="full" className={styles.texture} />}

            <Image src={phoneImg} alt={altText} className={styles.phone} quality={100} />
          </div>
          <div className={styles.outerContainer}>
            <div className={styles.contentContainer}>
              <Typography variant="heading500" className={styles.header}>
                {header}
              </Typography>
              <div className={styles.divider} />
            </div>

            <IconButton
              className={classNames(styles.close)}
              icon={{ Component: Close }}
              onClick={handleCloseClick}
              size="md"
              title={intl.formatMessage({ defaultMessage: 'Close' })}
              testId="close-button"
              variant="flat"
            />
            <div className={styles.contentContainer}>
              <div className={styles.logoAndRatingContainer}>
                <Image src={appIconSrc} alt={intl.formatMessage({ defaultMessage: 'AllTrails logo' })} quality={100} />
                <div className={styles.nameAndRatingContainer}>
                  <Typography variant="heading200">AllTrails</Typography>
                  <div className={styles.ratingContainer}>
                    <StarRating rating={5} variant="complex" />
                    <Typography variant="text100" component="span">
                      {/* TODO replace with dynamic ratings https://alltrails.atlassian.net/browse/GROW-4286 */}(
                      {isMounted && isAndroid() ? 4.8 : 4.9})
                    </Typography>
                  </div>
                </div>
              </div>
              <Button
                text={intl.formatMessage({ defaultMessage: 'Continue in app' })}
                onClick={() =>
                  deepLinkRedirect(
                    deeplink,
                    InstallPromptType.Interstitial,
                    promptLocation,
                    isAppsFlyerEnabled ? InstallPromptSource.NativeWithAppsflyerLink : InstallPromptSource.Native,
                    displayTrigger
                  )
                }
                testId="banner-button"
                variant="primary"
                size="md"
                allowWrap
                width="fullOnMobile"
              />
            </div>
          </div>
        </div>
      </FocusTrap>
    </ModalPortal>
  ) : null;
};

export default InstallInterstitial;
