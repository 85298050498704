import { useState, useCallback, useMemo } from 'react';

// This is for use with the Modal component in this package. That component does
// not support animated transitions between modal pages so this hook looks a little
// different than the one in the monorepo.
const useMultiStepModal = <K extends string>(
  initialKey: K | K[]
): {
  activeModal: K;
  goBack?: () => void;
  navigateTo: (modalKey: K) => void;
} => {
  const [modalStack, setModalStack] = useState<K[]>(Array.isArray(initialKey) ? initialKey : [initialKey]);

  const navigateTo = useCallback(
    (modalKey: K) => {
      if (modalStack[modalStack.length - 1] === modalKey) return;
      if (modalStack.length > 1 && modalStack[modalStack.length - 2] === modalKey) {
        setModalStack(modalStack.slice(0, -1));
        return;
      }

      setModalStack([...modalStack, modalKey]);
    },
    [modalStack]
  );

  const goBack = useMemo(
    () =>
      modalStack.length > 1
        ? () => {
            setModalStack(modalStack.slice(0, -1));
          }
        : undefined,
    [modalStack]
  );

  return {
    activeModal: modalStack[modalStack.length - 1],
    goBack,
    navigateTo
  };
};

export default useMultiStepModal;
