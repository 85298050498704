import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { InterstitialVariants } from '../constants';

const getVariantByTrigger = (trigger?: CarouselDisplayTrigger): InterstitialVariants => {
  if (trigger === CarouselDisplayTrigger.HitTheTrailCardDownloadMap) {
    return 'download';
  }
  if (trigger === CarouselDisplayTrigger.HitTheTrailCardNavigate) {
    return 'navigate';
  }
  if (trigger === CarouselDisplayTrigger.HitTheTrailCardLiveShare) {
    return 'liveshare';
  }
  return 'generic';
};

export default getVariantByTrigger;
