import Icon from '../components/Icon';

const MinusSm = Icon(
  'minus-sm',
  <>
    <path fillRule="evenodd" clipRule="evenodd" d="M7 11.25a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5H7Z" />
  </>
);

export default MinusSm;
