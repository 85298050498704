import { ChangeEventHandler, MouseEventHandler, ReactNode, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Size } from '../../types';
import CheckSm from '../../icons/CheckSm';
import MinusSm from '../../icons/MinusSm';
import styles from './styles/styles.module.scss';

type CheckboxProps = {
  className?: string;
  disabled?: boolean;
  id: string;
  inputLast?: boolean;
  labelElement: ReactNode;
  onChange: (selected: boolean) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
  selected: boolean | 'indeterminate';
  size?: Size<'sm' | 'md'>;
  testId: string;
  verticalAlignment?: 'center' | 'top';
};

const Checkbox = ({
  className,
  disabled,
  id,
  inputLast,
  labelElement,
  onChange,
  onClick,
  selected = false,
  size = 'md',
  testId,
  verticalAlignment = 'center'
}: CheckboxProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (selected === 'indeterminate') {
        inputRef.current.indeterminate = true;
      } else {
        inputRef.current.indeterminate = false;
      }
    }
  }, [selected]);

  const inputIcon = useMemo(() => {
    if (!selected) {
      return null;
    }
    const color = disabled ? '--color-text-disabled' : '--color-neutral-white';
    return selected === 'indeterminate' ? <MinusSm size={size} color={color} /> : <CheckSm size={size} color={color} />;
  }, [disabled, selected, size]);

  const onInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.checked);
  };

  const checkboxElement = (
    <div key={`checkbox-${id}`} className={styles.checkboxContainer}>
      <input
        ref={inputRef}
        checked={selected === true}
        disabled={disabled}
        onChange={onInputChange}
        onClick={onClick}
        type="checkbox"
        aria-labelledby={id}
        data-testid={testId}
      />
      <div aria-hidden="true" className={styles.checkbox}>
        {inputIcon}
      </div>
    </div>
  );

  const elements = inputLast ? [labelElement, checkboxElement] : [checkboxElement, labelElement];

  return (
    <label
      className={classNames(
        className,
        styles.container,
        styles[size],
        styles[verticalAlignment],
        disabled && styles.disabled,
        inputLast && styles.inputLast
      )}
      id={id}
    >
      {elements}
    </label>
  );
};

export default Checkbox;
